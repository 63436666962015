import { loadLanguageAsync } from './dictionary';

export const avatarUrlWithResize = (imageUrl, size) => {
  try {
    const imgURL = new URL(imageUrl);
    const ourHostImageUrl = new URL(`/static/${size}${imgURL.pathname.substring(1)}`, process.env.VUE_APP_STAND_URL);

    return ourHostImageUrl.href;
  } catch (e) {
    return '';
  }
};

export default avatarUrlWithResize;

export const storageIsAvailable = type => {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
    // everything except Firefox
      e.code === 22
      // Firefox
      || e.code === 1014
      // test name field too, because code might not be present
      // everything except Firefox
      || e.name === 'QuotaExceededError'
      // Firefox
      || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      && (storage && storage.length !== 0);
  }
};

export const cookiesAreEnabled = () => {
  try {
    document.cookie = 'cookietest=1';
    const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return cookiesEnabled;
  } catch (e) {
    return false;
  }
};

export const getCookie = name => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export function setCookie(name, value, options = {}) {
  const opt = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    opt.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const optionKey in opt) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = opt[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  console.log('setCookie', updatedCookie);
  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, '', {
    'max-age': -1
  });
}

export const getUnauthUserLocale = () => {
  let language;

  if (storageIsAvailable('localStorage') && cookiesAreEnabled() && localStorage.getItem('language')) {
    language = localStorage.getItem('language');
  } else {
    language = window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : 'en';
    language = language.substr(0, 2).toLowerCase();
  }
  loadLanguageAsync(language);
  return language;
};

export const getAvatarClass = (firstName, lastName) => {
  const input = firstName.concat(lastName).split('');
  let output = '';

  input.forEach(i => {
    output += i.charCodeAt(0).toString(2);
  });

  const res = (output.match(/1/g).length || 1) % 9;

  if (res <= 0 || res > 7) return 'avatar-7';

  return `avatar-${res}`;
};

const byteToHex = byte => (`0${byte.toString(16)}`).slice(-2);

export const generateId = (len = 20) => {
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);

  return Array.from(arr, byteToHex).join('');
};
